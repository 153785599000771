//
//
//
//

import {mapMutations, mapState, mapActions, mapGetters} from "vuex"
import record from "./record/record"
import $ from 'jquery'
import VueExtendLayout2 from 'vue-extend-layout';
import config from '/app.config'

export default {
  name: 'app',
  metaInfo() {
    return {
      meta: [
        { name: 'robots', content: 'noindex, nofollow'},
      ],
      bodyAttrs: {
        class: "layout-top-nav page-stand " + this.config.classes.bodyClass,
      },
      link: this.config.icons
    }
  },
  watch: {
    $route(_route, next) {
      this.$store.commit('setPreviousRoute', next);
      if (
          !this.token && (
            this.$router.currentRoute.name === 'agree-document'
            || this.$router.currentRoute.name === 'registration'
            || this.$router.currentRoute.name === 'forgot-password'
            || this.$router.currentRoute.name === 'trainings'
          )
      ) {
          //
      }

      this.setStartTime(null);
      this.setIsStartVideo(false);
      this.setTestStartTime(null);
      record.destroy();
      $('.modal').modal('hide');
      $('#modalMain').modal('hide');
      this.hideProfileModal()
    }
  },
  data: function () {
    return {
      config,
      prevRoute: null,
    }
  },
  async created() {
    this.getTrialTests();
    document.documentElement.style.setProperty('--success-color', this.config.colors.success);
    document.documentElement.style.setProperty('--warning-color', this.config.colors.warning);
    document.documentElement.style.setProperty('--no-img-course-color', this.config.colors.noImgCourse);
    if(this.config.colors.mainButtonTextColor !== undefined) {
      document.documentElement.style.setProperty('--main-button-text-color', this.config.colors.mainButtonTextColor);
    }
    if(this.config.colors.mainButtonBackground !== undefined) {
      document.documentElement.style.setProperty('--main-button-background', this.config.colors.mainButtonBackground);
    }
    if(this.config.colors.mainButtonBackground !== undefined) {
      document.documentElement.style.setProperty('--top-button-text-color', this.config.colors.topButtonTextColor);
    }
    if(this.config.colors.mainButtonBackground !== undefined) {
      document.documentElement.style.setProperty('--left-button-text-color', this.config.colors.leftButtonTextColor);
    }
    if(this.config.colors.mainButtonBackground !== undefined) {
      document.documentElement.style.setProperty('--left-button-background', this.config.colors.leftButtonBackground);
    }
    if(this.config.colors.leftHeaderColor !== undefined) {
      document.documentElement.style.setProperty('--left-header-color', this.config.colors.leftHeaderColor);
    }
    if(this.config.colors.mainBorderColor !== undefined) {
      document.documentElement.style.setProperty('--main-border-color', this.config.colors.mainBorderColor);
    }

    if(this.config.variables && this.config.variables.headerHeight !== undefined) {
      document.documentElement.style.setProperty('--header-height', this.config.variables.headerHeight+'px');
    }

    if(this.config.variables && this.config.variables.headerLogoHeight !== undefined) {
      document.documentElement.style.setProperty('--header-logo-height', this.config.variables.headerLogoHeight);
    }
    
    if(this.$router.currentRoute.name === "worker-login") {
      this.setAuthMethod("id")
    }

    if(this.token) {
      await this.getAuthSettings();
    }

    if (!this.token) {
      if (!this.isAuthRoute && !(this.$router.currentRoute.name === 'agree-document'))
      {
        this.$router.push({
          name: 'login'
        })
      }
    }

    if (!this.isAuthRoute
        && !(this.$router.currentRoute.name === 'agree-document')
    ) {
      if (this.isPhoneAuthMethod) {
        await this.getUserAndStudents()
      }
      
      await this.getStudentInfo()
    }
  },
  computed: {
    isAuthRoute() {
      return this.$router.currentRoute.path === '/login'
      || this.$router.currentRoute.path === '/registration'
      || this.$router.currentRoute.path === '/forgot-password'
      || this.$router.currentRoute.path === '/worker-login'
      || this.$router.currentRoute.name === 'registrationVnot'
      || this.$router.currentRoute.name === 'registrationVnotConfirm'
      || this.$router.currentRoute.path.includes('/trainings')
      || this.$router.currentRoute.path.includes('/training-test')
      || this.$router.currentRoute.path.includes('/section')
    },
    ...mapState([
      'token',
      'currentStudent',
      'authMethod',
      'isModalOpen',
    ]),
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
    ])
  },
  methods: {
    ...mapMutations([
      'setStartTime',
      'setIsStartVideo',
      'setTestStartTime',
      'hideProfileModal',
      'setAuthMethod'
    ]),
    ...mapActions([
      'getStudentInfo',
      'getUserAndStudents',
      'getAuthSettings',
      'getTrialTests',
    ])
  },
  components: {
    VueExtendLayout2
  },
}
