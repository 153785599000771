//
//
//
//

import config from '/app.config';

export default {
  name: "DocMaterial",
  props: {
    src: String,
  },
  data() {
    return {
      paddingtop: (config.variables.headerHeight || 50)-2+'px',
    }
  }
}
