import Vuex from 'vuex'
import Vue from "vue"
import createMultiTabState from 'vuex-multi-tab-state';

import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    worker: {},
    stats: {},
    history: [],
    courses: [],
    course: {},
    courseError: '',
    courseRestricted: '',
    lessons: {},
    tests: {},
    documents: [],
    email: '',
    emailError: '',
    code: '',
    codeError: '',
    pingTime: 10,
    maxPingTime: 300,
    showNotActivePopupTime: 300,
    startTime: null,
    testStartTime: null,
    startSaveVideo: false,
    token: localStorage.edu_app_token || '',
    trainingTest: {},

    showCourseLoader: false,
    showDocumentsLoader: false,

    docParamsToDownload: null,

    DEFAULT_PING_PERIOD: 10,
    DEFAULT_NOT_ACTIVE_PERIOD: 300,
    DEFAULT_NOT_ACTIVE_POPUP_PERIOD: 300,
    testDuration: 600000,
    isProfileModal: false,
    isUserModal: false,
    mobileDocContextMenu: false,
    students: [],
    currentStudent: Object,
    currentUser: {},
    timezones: {},
    storageStudentIdKey: 'education_current_student_id',
    authMethod: 'phone',
    idAuthMethod: 'id',
    phoneAuthMethod: 'phone',
    authCodeSendTimeout: 60,
    hasAccessToSign: true,
    hasAvailableDsSlot: true,
    isModalOpen: false,
    showDocumentsFirst: null,
    personalCertificateLoading: false,
    personalCertificate: null,
    dsRequest: null,
    certificatePdPolicyFileLink: null,
    certificateOfferFileLink: null,
    certificateOrderFileLink: null,
    certificateRequestFileLink: null,
    idDocuments: [],
    allowSendToTrudVsem: false,
    trialTests: [],
    isTrialTestLoading: false,
    searchTraining: '',
    previousRoute: '',
    externalDocuments: [],
    externalDocumentLoading: false,
    isCentriConnected: false,
  },
  mutations,
  actions,
  getters,
  plugins: [createMultiTabState({
    statesPaths: ['trialTests', 'worker', 'currentStudent', 'token', 'isTrainingSessionRun', 'showDocumentsFirst'],
  })]
})