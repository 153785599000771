//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import config from "/app.config";
  export default {
    name: "LessonHeader",
    props: {
      material: Object,
      isRestricted: Boolean
    },
    data() {
      return {
        config,
        routerLink: { params: this.$route.params, ...(this.$route.params.extNeedId ? { name: 'course' } : this.$route.params.paperId ? { to: '' } : { name: 'courses' } ) }
      }
    },
    methods: {
      handleClick() {
        if (this.$route.params.paperId)
          this.$router.push(this.token && this.token.length ? { path: '/'} : { name: 'login' });
        else 
          this.$router.push(this.routerLink);
      }
    },
    computed: {
      name() {
        if (this.isLesson) {
          return this.$i18n.t('headers.lesson')
        }

        if (this.isTest) {
          return this.$i18n.t('headers.test')
        }

        if (this.isPaper) {
          return this.$i18n.t('training_list.paper')
        }

        return ''
      },
      nameClass() {
        return this.isLesson ? 'badge-success' : 'badge-warning'
      },
      isLesson() {
        return (this.material && this.material.TYPE && this.material.TYPE === 'lesson')
      },
      isTest() {
        return (this.material && this.material.TYPE && this.material.TYPE === 'test')
      },
      isPaper() {
        return (this.material && this.material.TYPE && this.material.TYPE === 'training')
      },
      subtitle() {
        if (!this.material) {
          return ''
        }

        if (this.isPaper) {
          return this.material.SUBTITLE
        } else if (this.material.GROUP) {
          return this.$t('headers.category') + ': ' + this.material.GROUP
        }

        return ''
      },
      ...mapState(['token']),
    }
  }
