//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { mapActions } from 'vuex';

import ExternalDocumentsTrainingModal from './ExternalDocumentsTrainingModal.vue';

const ENEED_STATUS = {
  notComplete: 'notcomplete',
  complete: 'complete',
  expired: 'expired',
  finishBefore: 'should_be_completed'
}

export default {
  name: "ExternalNeed",
  components: { ExternalDocumentsTrainingModal },
  props: {
    need: {
      type: Object,
    },
    ecCompany: {
      type: Object,
    },
    index: {
      type: Number,
    }
  },
  data() {
    return {
      showCollapse: false,      
      showModal: false,
      selectedTraining: null,
      showNeedsInfo: false,
    }
  },
  created() {
    this.showNeedsInfo = this.need.STATUS.UF_CODE !== ENEED_STATUS.complete;
  },
  computed: {
    getDateStartEducation(){
      return (this.need && this.need.DATE_START_EDUCATION) && moment(this.need.DATE_START_EDUCATION, 'DD.MM.YYYY hh:mm:ss').locale('ru').format('DD.MM.YYYY');
    },
    getStatusClass() {
      switch (this.need.STATUS.UF_CODE) {
        case (ENEED_STATUS.notComplete):
          if (this.need.HISTORY.length)
            return 'complete_before';
          return 'notComplete';
        case (ENEED_STATUS.complete):
          if (this.need.DATE_END)
            return 'before';
          return 'complete';
        case (ENEED_STATUS.expired):
          return 'expired';
        case (ENEED_STATUS.finishBefore):
          return 'should_be_completed';
        default:
          return 'unknown' 
      }
    },
  },
  methods: {
    showTrainingModal(id) {
      this.showModal = true;
      this.selectedTraining = id;
    },
    hideTrainingModal() {
      this.showModal = false;
      this.selectedTraining = null;
    },
    openCourse() {
      this.$router.push({ name: 'course', params: { 
        id: Number(this.need.ACTUAL_PARAMS.VALUE), 
        type: (this.need.ACTUAL_PARAMS.NAME === 'trainingId') ? 'exttraining' : 'ext',
      }});
    },
    ...mapActions(['getCourse']),
  },
}
