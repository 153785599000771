import axios from "axios";
import store from '../store/index'
import config from '../../app.config'
import router from "@/router";
import notification from "@/notification/notification";

const BASE_URL = config.siteUrl;

const SOURCE = 'Education';
const WORKER_METHOD = 'getStudentProfile';
const ADD_EMAIL_METHOD = 'addStudentEmail';
const CONFIRM_EMAIL_METHOD = 'confirmStudentEmail';
const CONFIRM_USER_EMAIL_METHOD = 'confirmUserEmail';
const CONFIRM_USER_PHONE_METHOD = 'confirmUserPhone';
const CONFIRM_DS_EMAIL_METHOD = 'confirmDsEmail';
const CONFIRM_DS_PHONE_METHOD = 'confirmDsPhone';
const COURSES_METHOD = 'getStudentCourses';
const COURSE_METHOD = 'getStudentCourse';
const PHOTO_ADD_METHOD = 'savePhoto';
const VIDEO_ADD_METHOD = 'saveVideo';
const START_SESSION_METHOD = 'createSession';
const START_TRIAL_SESSION_METHOD = 'createTrialSession';
const PING_SESSION_METHOD = 'pingSession';
const TEST_OUTDATED_METHOD = 'testTimedOut';
const STATISTIC_METHOD = 'getStudentStats';
const DOCUMENTS_METHOD = 'getStudentDocsByFilter';
const DOCUMENTS_SIGN_RULES_METHOD = 'getSignRulesForType';
const PERSONNEL_DOCUMENTS_METHOD = 'getPersonnelDocs';
const GET_DOCUMENT_METHOD = 'getDoc';
const GET_DOCUMENT_HASH_METHOD = 'getHashForDs';
const GET_DS_REQUEST_SCAN_FILE = 'getDsRequestScanFile';
const DOWNLOAD_DOC = 'downloadDoc';
const GET_PAYROLL = 'getWagesListPreview';
const DOWNLOAD_PAYROLL = 'getWagesList';
const DOCUMENTS_FILTERS_METHOD = 'getStudentDocsFilters';
const DOCUMENTS_PREVIEW_METHOD = 'genStudentDocPreview';
const SCAN_PREVIEW_METHOD = 'getStudentDocScanPreview';
const SETTINGS_METHOD = 'getStudentSettings';
const SIGN = 'dsProcessDoc';
const SIGN_PEP = 'pepProcessDoc';
const DOWNLOAD_XLS = 'genStudentDocs';
const GET_TRIAL_TESTS = 'getTrialTestsTree';
const GET_TEST_QUESTIONS = 'getTestQuestions';
const GET_PAPER_QUESTIONS = 'getTestPaperQuestions';
const LOGIN_METHOD = 'login';
const REGISTER_METHOD = 'register';
const SET_PHONE = 'setPhone';
const SET_PIN = 'setPin';
const RESET_PASSWORD = 'resetPassword';
const CONFIRM_RESET = 'confirmReset';
const SET_NEW_PIN = 'setNewPin';
const AUTH_METHOD = 'getAuthMethod';
const GET_CODE_METHOD = 'sendLoginConfirmation';
const CONFIRM_LOGIN_METHOD = 'confirmLogin';
const CONFIRM_REGISTRATION = 'confirmRegistration';
const LOGOUT_METHOD = 'logout';
const GEN_DOC = 'genDocPreview';
const GET_USER_SETTINGS = 'getUserProfile';
const GET_STUDENTS = 'getStudents';
const GET_TIMEZONES = 'getTimezones';
const SAVE_USER_SETTINGS = 'saveProfile';
const GET_CERTIFICATE = 'getCertDs';
const GET_REQUEST = 'getRequestDs';
const ADD_CERTIFICATE = BASE_URL + '/api/';
const DOWNLOAD_STUDENT_COMPELETY_DS_DOCS = 'getStudentCompletelyDSDocsDownloadPath';
const DOWNLOAD_STUDENT_DS_DOCS = 'getStudentDocsWithSignDownloadPath';
const ADD_DS_EMAIL = 'addDsEmail';
const ADD_DS_PHONE = 'addDsPhone';
const REGISTER_BY_HASH = 'registerByHash';
const SEND_DOC_TO_TRUD_VSEM = 'sendDocToTrudVsem';
const GET_LINK_TO_TRUD_VSEM = 'getLinkToTrudVsemForSign';
const GET_ACTIVE_TRAINING_NEED = 'getActive';
const TRAINING_NEED_SOURCE = 'TrainingNeed';
const GET_STUDENT_EXTERNAL_COURSES = 'getStudentExternalCourses';

const VNOT_SOURCE = 'Vnot';
const VNOT_REGISTRATION = 'registration';
const VNOT_REGISTRATION_CONFIRM = 'registrationConfirm';

const CONNECT_URL = BASE_URL + '/api/connect.php';
const SAVE_TEST_RESULTS_URL = BASE_URL + '/api/?action=saveTestResults';

const checkResponse = (response, method) => {
  if (response && response.data && response.data.error) {
    if (response.data.error === 401 && router.currentRoute.name !== 'registrationVnot' && router.currentRoute.name !== 'registrationVnotConfirm') {
      router.push({
        name: 'login'
      })
    }

    if ((response.data.error === 400 || response.data.error === 503) && response.data.errorStr) {
      if (!(method === 'pingSession' && response.data.errorStr === 'Сессия истекла'))
        notification.error(response.data.errorStr)
    }

    if (!response.data.errorStr) {
      notification.error('Непредвиденная ошибка, обратитесь к администратору')
    }
  } else if (response && response.data && response.data.result && response.data.result.warning) {
    notification.warning(response.data.result.warning)
  }
}

const request = async (method, params = {}, config = [], source = SOURCE) => {
  let options = {};
  const isAuthNotNeed =  (method !== START_TRIAL_SESSION_METHOD || method !== TEST_OUTDATED_METHOD || 
    method !==  PING_SESSION_METHOD || method !== TEST_OUTDATED_METHOD || method !== GET_PAPER_QUESTIONS) && Boolean(store.state.token);
  if (method !== 'login' && isAuthNotNeed) {
    options = {
      headers: {
        Authorization: 'Bearer ' + (store.state.token || ''),
      },
    }
  }

  try {
    if (store.state.currentStudent && store.state.currentStudent.ID) {
      params.student_id = store.state.currentStudent.ID
    }
    const response = axios.post(BASE_URL + '/api/', {
      method, params, config, source
    }, options)

    await response.then(result => {
      checkResponse(result, method);
    }).catch(() => {
      notification.error('Непредвиденная ошибка, обратитесь к администратору')
    })

    return response
  } catch (e) {
    console.log(e.message());
  }
};

const post = (url, data) => {
    if (store.state.currentStudent && store.state.currentStudent.ID) {
      if (typeof data.append === 'function') {
        data.append('student_id', store.state.currentStudent.ID)
      }
    }

    const response = axios.post(url, data,{
    ...store.state.token && {
      headers: {
        Authorization: 'Bearer ' + (store.state.token || '')
      }
    }})

    response.then(result => {
        checkResponse(result)
    })

  return response
}

export default {
  worker() {
    return request(WORKER_METHOD);
  },
  courses() {
    return request(COURSES_METHOD);
  },
  course(id) {
    return request(COURSE_METHOD, id);
  },
  addEmail(email, subscribe) {
    return request(ADD_EMAIL_METHOD, {
      email, subscribe
    })
  },
  confirmEmail(code) {
    return request(CONFIRM_EMAIL_METHOD, {
      code
    })
  },
  confirmUserEmail(code) {
    return request(CONFIRM_USER_EMAIL_METHOD, {
      code
    })
  },
  confirmUserPhone(code) {
    return request(CONFIRM_USER_PHONE_METHOD, {
      code
    })
  },
  confirmDsEmail(code) {
    return request(CONFIRM_DS_EMAIL_METHOD, {
      code
    })
  },
  confirmDsPhone(code) {
    return request(CONFIRM_DS_PHONE_METHOD, {
      code
    })
  },
  savePhoto(sessionId, id, photo) {
    return request(PHOTO_ADD_METHOD, {
      session_id: sessionId,
      id, photo
    })
  },
  saveVideo(sessionId, id, video) {
    return request(VIDEO_ADD_METHOD, {
      session_id: sessionId,
      id, video
    })
  },
  startLessonSession(courseId, lessonId, needId) {
    return request(START_SESSION_METHOD, {
      lesson_id: lessonId,
      course_id: courseId,
      ...needId
    });
  },
  startTestSession(courseId, testId, extNeedId) {
    return request(START_SESSION_METHOD, {
      test_id: testId,
      course_id: courseId,
      ...extNeedId,
      student_id: (store.state.currentStudent && store.state.currentStudent.ID) ? store.state.currentStudent.ID : store.state.worker.id,
    });
  },
  startTrialTestSession(testId) {
    return request(START_TRIAL_SESSION_METHOD, {
      test_id: testId,
    });
  },
  ping(sessionId, courseId, lessonId, needId) {
    return request(PING_SESSION_METHOD, {
      session_id: sessionId,
      lesson_id: lessonId,
      course_id: courseId,
      ...needId,
    });
  },
  pingTest(sessionId, courseId, testId, needId) {
    return request(PING_SESSION_METHOD, {
      session_id: sessionId,
      test_id: Number(testId),
      course_id: courseId,
      ...needId,
    });
  },
  testTimedOut(sessionId, testId) {
    return request(TEST_OUTDATED_METHOD, {
      session_id: sessionId,
      id: testId
    });
  },
  getStats() {
    return request(STATISTIC_METHOD);
  },
  getDocuments(filter, limit, pageNumber) {
    const params = {filter}

    if (parseInt(limit)) {
      params.limit = parseInt(limit);
      params.limit = parseInt(limit)

    }

    if (parseInt(pageNumber)) {
      params.pageNumber = parseInt(pageNumber)
    }

    return request(DOCUMENTS_METHOD, params);
  },
  getSignRulesForType(type) {
    const params = {type: type}

    return request(DOCUMENTS_SIGN_RULES_METHOD, params)
  },
  getPersonnelDocuments(limit, pageNumber) {
    const params = {}

    if (parseInt(limit)) {
      params.limit = parseInt(limit);
      params.offset = parseInt(pageNumber);
    }

    return request(PERSONNEL_DOCUMENTS_METHOD, params);
  },
  getDocumentsFilters() {
    return request(DOCUMENTS_FILTERS_METHOD);
  },
  getDocumentsPreview(id, doctype = null) {
    let params = {
      filter: {
        id
      }
    }

    if (doctype) {
      params.doctype = doctype
    }

    return request(DOCUMENTS_PREVIEW_METHOD, params)
  },
  getScanPreview(id, scanId) {
    return request(SCAN_PREVIEW_METHOD, {
      filter: {
        id, scanId
      }
    })
  },
  downloadDocs(filter, modalFilter) {
    return request(DOWNLOAD_XLS, {
      filter,
      ...modalFilter
    })
  },
  getStudentDocsCompeletyDS(filter) {
    return request(DOWNLOAD_STUDENT_COMPELETY_DS_DOCS, filter)
  },
  getStudentDocsSign(id) {
    return request(DOWNLOAD_STUDENT_DS_DOCS, {
      filter: {
        id
      }
    })
  },
  getCentrifugeSettings() {
    return post(CONNECT_URL, {
      student_id: store.state.currentStudent.ID
    })
  },
  registerByHash(params){
    return request(REGISTER_BY_HASH, params)
  },
  login(params){
    return request(LOGIN_METHOD, params)
  },
  confirmLogin(params){
    return request(CONFIRM_LOGIN_METHOD, params)
  },
  getCode(params) {
    return request(GET_CODE_METHOD, params)
  },
  logout() {
    return request(LOGOUT_METHOD);
  },
  getDsRequestScanFile(data) {
      return request(GET_DS_REQUEST_SCAN_FILE, data)
  },
  getStudentSettings(){
    return request(SETTINGS_METHOD);
  },
  getTrialTests(){
    return request(GET_TRIAL_TESTS);
  },
  getPaperQuestions(test_id, paper_id, filter){
    return request(GET_PAPER_QUESTIONS, {
      test_id, ...(paper_id && { paper_id }), filter
    });
  },
  getTestQuestions(id){
    return request(GET_TEST_QUESTIONS, {
      id
    });
  },
  genDoc(ids) {
    return request(GEN_DOC, {
      filter: {
        id: ids
      }
    })
  },
  saveTestResults(formData) {
    return post(SAVE_TEST_RESULTS_URL, formData)
  },
  getUserProfile(){
    return request(GET_USER_SETTINGS);
  },
  saveUserSettings(params){
    return request(SAVE_USER_SETTINGS, params);
  },
  addDsEmail(params){
    return request(ADD_DS_EMAIL, params);
  },
  addDsPhone(params){
    return request(ADD_DS_PHONE, params);
  },
  register(params) {
    return request(REGISTER_METHOD, params);
  },
  setPhone(params) {
    return request(SET_PHONE, params);
  },
  confirmRegistration(params) {
    return request(CONFIRM_REGISTRATION, params);
  },
  setPin(params) {
    return request(SET_PIN, params);
  },
  getAuthMethod() {
    return request(AUTH_METHOD);
  },
  resetPassword(params) {
    return request(RESET_PASSWORD, params);
  },
  confirmReset(params) {
    return request(CONFIRM_RESET, params);
  },
  setNewPin(params) {
    return request(SET_NEW_PIN, params);
  },
  getStudents() {
    return request(GET_STUDENTS);
  },
  getTimezones() {
    return request(GET_TIMEZONES);
  },
  downloadDoc(id, type, forSignVal) {
    let forSign = typeof forSignVal !== "undefined" && forSignVal === true;
    const params = {
      id, type, forSign
    }

    return request(DOWNLOAD_DOC, params)
  },
  getPayroll(params){
    return request(GET_PAYROLL, params)
  },
  downloadPayroll(dateBegin){
    return request(DOWNLOAD_PAYROLL, {
      dateBegin
    })
  },

  sign(params){
    return request(SIGN, {
      ...params
    })
  },

  getDoc(id, type) {
    return request(GET_DOCUMENT_METHOD, {
      id, type
    })
  },

  getDocHash(uid, type) {
    return request(GET_DOCUMENT_HASH_METHOD, {
      uid, type
    })
  },

  getCertificate() {
    return request(GET_CERTIFICATE)
  },

  getDsRequest() {
    return request(GET_REQUEST)
  },

  addCertificate(params) {
    return post(ADD_CERTIFICATE, params)
  },

  sendDocToTrudVsem(params) {
    return request(SEND_DOC_TO_TRUD_VSEM, params)
  },

  getLinkToTrudVsem(params) {
    return request(GET_LINK_TO_TRUD_VSEM, params)
  },

  vnotRegistrations(params) {
    return request(VNOT_REGISTRATION, params, {}, VNOT_SOURCE)
  },

  vnotRegistrationsConfirm(params) {
    return request(VNOT_REGISTRATION_CONFIRM, params, {}, VNOT_SOURCE)
  },

  signPep(params){
    return request(SIGN_PEP, params, {}, SOURCE);
  },
  
  getTraining() {
    return request(GET_ACTIVE_TRAINING_NEED, { filter: { UF_WORKER_ID: store.state.worker.id }}, [], TRAINING_NEED_SOURCE);
  },

  getStudentExternalCourses(id) {
    return request(GET_STUDENT_EXTERNAL_COURSES, { student_id: id}, [], SOURCE);
  }
}