//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/api';
import { mapMutations } from 'vuex';

import ExternalDocumentsTable from './ExternalDocumentsTable.vue';
import GetArchiveModal from "@/components/documents/modals/GetArchiveModal";

export default {
  name: 'ExternalDocumentsTrainingModal',
  props: {
    trainingId: Number,
  },
  components: { ExternalDocumentsTable, 
    GetArchiveModal 
  },
  data() {
    return {
      documents: [],
      total: 0,
    }
  },
  async created() {
    this.getExternalDocuments();
  },
  methods: {
    async getExternalDocuments(limit = null, pageNumber = 1){
      this.setExternalDocumentLoading(true);
      try {
        const response = await api.getDocuments({ 
          trainingId: this.trainingId, 
          fromExternalEducation: true,
        },limit, pageNumber);
        if (response.data) {
          this.documents = response.data.result.documents.map((document) => ({ ...document, checked: false }));
          this.total = response.data.result.total;
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.setExternalDocumentLoading(false);
      }
    },
    ...mapMutations(['setExternalDocumentLoading']),
  }
}
